{
  "a11y": {
    "loading_page": "الصفحة قيد التحميل، يرجى الانتظار",
    "loading_titled_page": "الصفحة {0} قيد التحميل ، يرجى الانتظار",
    "locale_changed": "تم تغيير اللغة إلى {0}",
    "locale_changing": "يتم تغيير اللغة، يرجى الانتظار",
    "route_loaded": "تم تحميل الصفحة {0}"
  },
  "account": {
    "avatar_description": "صورة حساب {0}",
    "blocked_by": "تم حظرك من قبل هذا المستخدم",
    "blocked_domains": "النطاقات المحظورة",
    "blocked_users": "المستخدمون المحظورون",
    "blocking": "محظور",
    "bot": "حساب آلي",
    "favourites": "المفضلة",
    "follow": "إتبع",
    "follow_back": "إعادة متابعة",
    "follow_requested": "طلبت المتابعة",
    "followers": "متابِعون",
    "followers_count": "لا يوجد متابعون|{0} متابِع|{0} متابِعين|{0} متابِعون|{0} متابِع|{0} متابِع",
    "following": "مُتابَع",
    "following_count": "لا يتبع أحدا|{0} مُتابَع|{0} مُتابَعين|{0} مُتابَعون|{0} مُتابَع|{0} مُتابَع",
    "follows_you": "يتابعك",
    "go_to_profile": "اعرض الصفحة التعريفية",
    "joined": "انضم",
    "moved_title": "أشار إلى أن حسابهم الجديد أصبح على",
    "muted_users": "المستخدمون المكتومون",
    "muting": "قُمت بكتم",
    "mutuals": "المتبادلون",
    "notifications_on_post_disable": "التوقف عن إشعاري عندما ينشر {username}",
    "notifications_on_post_enable": "إشعاري عندما ينشر {username}",
    "pinned": "المثبتة",
    "posts": "المنشورات",
    "posts_count": "{0} منشورات|{0} منشور|{0} منشورين|{0} منشورات|{0} منشور|{0} منشور",
    "profile_description": "{0} رأسية حساب",
    "profile_unavailable": "حساب غير متوفر",
    "unblock": "إلغاء حظر",
    "unfollow": "إلغاء متابعة",
    "unmute": "إلغاء كتم",
    "view_other_followers": "قد لا يتم عرض المتابعين من خوادم مختلفين عن الخاص بك.",
    "view_other_following": "قد لا يتم عرض من تتابع من خوادم مختلفين عن الخاص بك."
  },
  "action": {
    "apply": "تطبيق",
    "bookmark": "إضافة إلى العلامات المرجعية",
    "bookmarked": "مضاف إلى العلامات المرجعية",
    "boost": "إعادة نشر",
    "boost_count": "{0}",
    "boosted": "أعيد نشرها",
    "clear_publish_failed": "مسح أخطاء النشر",
    "clear_upload_failed": "مسح أخطاء تحميل الملف",
    "close": "أغلق",
    "compose": "منشور جديد",
    "confirm": "تأكد",
    "edit": "تعديل",
    "enter_app": "أدخل التطبيق",
    "favourite": "إضافة إلى المفضلة",
    "favourite_count": "{0}",
    "favourited": "مضاف إلى المفضلة",
    "more": "المزيد",
    "next": "التالي",
    "prev": "السابق",
    "publish": "نشر",
    "reply": "رد",
    "reply_count": "{0}",
    "reset": "إعادة الضبط",
    "save": "حفظ",
    "save_changes": "حفظ التغييرات",
    "sign_in": "تسجيل الدخول",
    "switch_account": "تغيير الحساب",
    "vote": "تصويت"
  },
  "app_desc_short": "منصة تواصل ماستودون رشيقة",
  "app_logo": "Elk شعار",
  "app_name": "Elk",
  "attachment": {
    "edit_title": "وصف",
    "remove_label": "قم بإزالة المرفق"
  },
  "command": {
    "activate": "فعل",
    "complete": "أكمل",
    "compose_desc": "اكتب منشورا جديدا",
    "n-people-in-the-past-n-days": "{0} أشخاص في الأيام ال {1} الماضية",
    "select_lang": "اختر اللغة",
    "sign_in_desc": "إضافة حساب قائم",
    "switch_account": "التبديل إلى {0}",
    "switch_account_desc": "قم بالتبديل إلى حساب آخر",
    "toggle_dark_mode": "تبديل الوضع الليلي",
    "toggle_zen_mode": "تبديل الوضع الهادئ"
  },
  "common": {
    "confirm_dialog": {
      "cancel": "لا",
      "confirm": "نعم",
      "title": "هل أنت متأكد؟"
    },
    "end_of_list": "نهاية القائمة",
    "error": "حدث خطأ",
    "in": "في",
    "not_found": "404 غير موجود",
    "offline_desc": "يبدو أنك غير متصل بالإنترنت. يرجى التحقق من اتصالك."
  },
  "compose": {
    "draft_title": "مسودة {0}",
    "drafts": "المسودات ({v})"
  },
  "confirm": {
    "block_account": {
      "cancel": "إلغاء",
      "confirm": "حظر",
      "title": "هل أنت متأكد أنك تريد حظر {0}؟"
    },
    "block_domain": {
      "cancel": "إلغاء",
      "confirm": "حظر",
      "title": "هل أنت متأكد أنك تريد حظر {0}؟"
    },
    "common": {
      "cancel": "لا",
      "confirm": "نعم"
    },
    "delete_posts": {
      "cancel": "إلغاء",
      "confirm": "حذف",
      "title": "هل أنت متأكد أنك تريد حذف هذا المنشور؟"
    },
    "mute_account": {
      "cancel": "إلغاء",
      "confirm": "كتم",
      "title": "هل أنت متأكد أنك تريد كتم {0}؟"
    },
    "show_reblogs": {
      "cancel": "إلغاء",
      "confirm": "اعرض",
      "title": "هل أنت متأكد أنك تريد إظهار تعزيزات من {0}؟"
    },
    "unfollow": {
      "cancel": "إلغاء",
      "confirm": "إلغاء المتابعة",
      "title": "هل أنت متأكد أنك تريد إلغاء المتابعة؟"
    }
  },
  "conversation": {
    "with": "مع"
  },
  "custom_cards": {
    "stackblitz": {
      "lines": "الخطوط {0}",
      "open": "افتح",
      "snippet_from": "مقتطف من {0}"
    }
  },
  "error": {
    "account_not_found": "حساب {0} غير موجود",
    "explore-list-empty": "لا توجد مشاركات شائعة الآن. تحقق مرة أخرى لاحقًا!",
    "file_size_cannot_exceed_n_mb": "لا يمكن أن يتجاوز حجم الملف {0} ميغابايت",
    "sign_in_error": "لا يمكن الاتصال بالموقع",
    "status_not_found": "لا يمكن إيجاد المنشور",
    "unsupported_file_format": "لا يمكن تحميل هذا النوع من الملفات"
  },
  "help": {
    "build_preview": {
      "desc1": "أنت تشاهد حاليًا إصدار معاينة من Elk عامة - {0}.",
      "desc2": "قد يحتوي على تغييرات لم تتم مراجعتها أو حتى ضارة.",
      "desc3": "لا تسجل الدخول بحسابك الحقيقي.",
      "title": "معاينة النشر"
    },
    "desc_highlight": "توقع بعض الأخطاء والميزات المفقودة هنا وهناك.",
    "desc_para1": "نشكرك على اهتمامك بتجربة Elk ، عميل ماستدون العام!",
    "desc_para2": "نحن نعمل بجد على التطوير وتحسينه بمرور الوقت. وسندعوك قريبًا للانضمام إلى القوة بمجرد أن نجعلها مفتوحة المصدر قريبًا!",
    "desc_para3": "قبل ذلك ، للمساعدة في تعزيز التنمية ، يمكنك رعاية أعضاء فريقنا من خلال الروابط أدناه.",
    "desc_para4": "قبل ذلك ، إذا كنت ترغب في المساعدة في الاختبار أو تقديم التعليقات أو المساهمة ،",
    "desc_para5": "تواصل معنا على GitHub",
    "desc_para6": "و شارك معنا",
    "title": "Elk في عرض مسبق"
  },
  "language": {
    "search": "بحث"
  },
  "list": {
    "add_account": "إضافة حساب إلى القائمة",
    "modify_account": "تعديل القوائم مع الحساب",
    "remove_account": "إزالة الحساب من القائمة"
  },
  "menu": {
    "block_account": "حظر {0}",
    "block_domain": "حظر المجال {0}",
    "copy_link_to_post": "انسخ الرابط إلى هذا المنشور",
    "copy_original_link_to_post": "انسخ الرابط الأصلي لهذا المنشور",
    "delete": "حذف",
    "delete_and_redraft": "حذف وإعادة صياغة",
    "delete_confirm": {
      "cancel": "إلغاء",
      "confirm": "حذف",
      "title": "هل أنت متأكد أنك تريد حذف هذا المنشور؟"
    },
    "direct_message_account": "إرسال رسالة مباشرة إلى {0}",
    "edit": "تعديل",
    "hide_reblogs": "إخفاء المشاركات من {0}",
    "mention_account": "أذكر {0}",
    "mute_account": "كتم {0}",
    "mute_conversation": "تجاهل هذا المنصب",
    "open_in_original_site": "فتح في الموقع الأصلي",
    "pin_on_profile": "تثبيت على حسابك الشخصي",
    "share_post": "شارك هذا المنشور",
    "show_favourited_and_boosted_by": "أظهر من فضل وشارك",
    "show_reblogs": "عرض المشاركات من {0}",
    "show_untranslated": "عرض بدون ترجمة",
    "toggle_theme": {
      "dark": "التغيير إلى الوضع الداكن",
      "light": "التغيير إلى الوضع الفاتح"
    },
    "translate_post": "ترجم المنشور",
    "unblock_account": "رفع الحظر عن {0}",
    "unblock_domain": "رفع الحظر عن النطاق {0}",
    "unmute_account": "إلغاء كتم الحساب {0}",
    "unmute_conversation": "إلغاء كتم المحادثة",
    "unpin_on_profile": "إلغاء التثبيت من الملف الشخصي"
  },
  "nav": {
    "back": "العودة",
    "blocked_domains": "المجالات المحظورة",
    "blocked_users": "المستخدمين المحظورين",
    "bookmarks": "العلامات المرجعية",
    "built_at": "Built {0}",
    "compose": "تأليف",
    "conversations": "المحادثات",
    "explore": "استكشف",
    "favourites": "المفضلة",
    "federated": "الفديرالية",
    "home": "الرئيسيّة",
    "list": "قائمة",
    "lists": "القوائم",
    "local": "المحلي",
    "muted_users": "المستخدمون المكتموصين",
    "notifications": "التنبيهات",
    "privacy": "خصوصية",
    "profile": "الصفحة التعريفية",
    "search": "البحث",
    "select_feature_flags": "تبديل علامات الميزات",
    "select_font_size": "حجم الخط",
    "select_language": "اختار اللغة",
    "settings": "الإعدادات",
    "show_intro": "عرض المقدمة",
    "toggle_theme": "تغيير الوضع",
    "zen_mode": "الوضع الهادئ"
  },
  "notification": {
    "favourited_post": "أُعجِب بمنشورك",
    "followed_you": "بدأ في متابعتك",
    "followed_you_count": "لم يتبعك أحد|تبعك شخص واحد|تبعك شخصان|تبعك {0} أشخاص|تبعك {0} شخص| تبعك {0} شخص",
    "missing_type": "MISSING notification.type:",
    "reblogged_post": "اعاد نشر منشورك",
    "request_to_follow": "طلب(ت) متابعتك",
    "signed_up": "تسجل",
    "update_status": "قام(ت) بتحديث حالته(ا)"
  },
  "placeholder": {
    "content_warning": "اكتب تحذيرك هنا",
    "default_1": "ماذا يدور في ذهنك؟",
    "reply_to_account": "الرد على {0}",
    "replying": "الرد",
    "the_thread": "المحادثة"
  },
  "pwa": {
    "dismiss": "تجاهل",
    "install": "تحميل",
    "install_title": "تحميل Elk",
    "title": "يتوفر تحديث Elk جديد",
    "update": "تحديث",
    "update_available_short": "تحديث Elk",
    "webmanifest": {
      "canary": {
        "description": "نسخة ويب رشيقة لماستودون (النسخة الإنشائية)",
        "name": "Elk (النسخة الإنشائية)",
        "short_name": "Elk (النسخة الإنشائية)"
      },
      "dev": {
        "description": "نسخة ويب رشيقة لماستودون (النسخة التطويرية)",
        "name": "Elk (النسخة التطويرية)",
        "short_name": "Elk (النسخة التطويرية)"
      },
      "preview": {
        "description": "نسخة ويب رشيقة لماستودون (معاينة)",
        "name": "Elk (معاينة)",
        "short_name": "Elk (معاينة)"
      },
      "release": {
        "description": "نسخة ويب رشيقة لماستودون",
        "name": "Elk",
        "short_name": "Elk"
      }
    }
  },
  "search": {
    "search_desc": "ابحث عن الأشخاص والهاشتاج",
    "search_empty": "لم يتم العثور على أي نتائج لشروط البحث الخاصة بك"
  },
  "settings": {
    "about": {
      "label": "حول Elk",
      "meet_the_team": "تعرف على فريقنا",
      "sponsor_action": "تمويل Elk",
      "sponsor_action_desc": "لتمويل تطوير Elk والمساعدة في تحسينه",
      "sponsors": "الرعاة",
      "sponsors_body_1": "تم تمويل Elk من قبل الشركات والأفراد التاليين:",
      "sponsors_body_2": "وكذا من قبل الشركات التالية:",
      "sponsors_body_3": "إذا كنت تستمتع بإستخدام Elk، فنحن نشجعك على التبرع لدعم المشروع.",
      "version": "الإصدار"
    },
    "account_settings": {
      "description": "قم بتحرير إعدادات حسابك في موقع ماستودون الأصل",
      "label": "إعدادت الحساب"
    },
    "feature_flags": {
      "github_cards": "بطاقات GitHub",
      "title": "الميزات التجريبية",
      "user_picker": "الشريط الجانبي لمبدل المستخدم",
      "virtual_scroll": "التمرير الافتراضي"
    },
    "interface": {
      "color_mode": "وضع اللون",
      "dark_mode": "الوضع الداكن",
      "default": " (إفتراضي)",
      "font_size": "حجم الخط",
      "label": "واجهه المستخدم",
      "light_mode": "وضع الضوء",
      "system_mode": "النظام",
      "theme_color": "وضع المظهر"
    },
    "language": {
      "display_language": "اللغة المعروضة",
      "label": "اللغة"
    },
    "notifications": {
      "label": "التنبيهات",
      "notifications": {
        "label": "إعدادات التنبيهات"
      },
      "push_notifications": {
        "alerts": {
          "favourite": "المفضلة",
          "follow": "متابعين جدد",
          "mention": "المنشورات التي تذكرني",
          "poll": "استطلاعات الرأي",
          "reblog": "إعادة نشر منشورك",
          "title": "ما هي التنبيهات التي تريد تلقيها؟"
        },
        "description": "تلقي التنبيهات حتى عندما لا تستخدم Elk.",
        "instructions": " لا تنس حفظ التغييرات باستخدام الزر @:settings.notifications.push_notifications.save_settings",
        "label": "إعدادات التنبيهات",
        "policy": {
          "all": "من اي شخص",
          "followed": "من الناس الذين أتابعهم",
          "follower": "من الناس الذين يتبعونني",
          "none": "من لا أحد",
          "title": "من الذي يمكنني تلقي التنبيهات منه؟"
        },
        "save_settings": "حفظ التغييرات الإعدادات",
        "subscription_error": {
          "clear_error": "خطأ في المسح",
          "permission_denied": "تم رفض الإذن: قم بتمكين التنبيهات في متصفحك.",
          "request_error": "حدث خطأ أثناء طلب الاشتراك ، حاول مرة أخرى وإذا استمر الخطأ ، يرجى إبلاغ Elk بالمشكلة.",
          "title": "الاشتراك في التنبيهات غير ناجح",
          "too_many_registrations": "بسبب القيود في المتصفح ، لا يمكن لـ Elk استخدام خدمة التنبيهات الفورية لعدة حسابات على خوادم مختلفة. يجب عليك إلغاء الاشتراك في التنبيهات الفورية على حسابات أخرى والمحاولة مرة أخرى."
        },
        "title": "إعدادات التنبيهات",
        "undo_settings": "تراجع عن تغييرات الإعدادات",
        "unsubscribe": "تعطيل التنبيهات",
        "unsupported": "متصفحك لا يدعم التنبيهات",
        "warning": {
          "enable_close": "أغلق",
          "enable_description": "لتلقي التنبيهات عندما لا يكون Elk مفتوحًا، قم بتمكين تنبيهات النظام. يمكنك التحكم بدقة في أنواع التفاعلات التي تنشئ تنبيهات النظام عبر الزر \"Show Settings\" أعلاه.",
          "enable_description_desktop": "لتلقي إشعارات عندما لا يكون Elk مفتوحًا ، قم بتمكين دفع الإخطارات. يمكنك التحكم بدقة في أنواع التفاعلات التي تولد إشعارات الدفع \"Settings > Notifications > Push notifications settings\"",
          "enable_description_mobile": "يمكنك أيضًا الوصول إلى الإعدادات باستخدام قائمة التنقل \"Settings > Notifications > Push notification settings\".",
          "enable_description_settings": "لتلقي إشعارات عندما لا يكون Elk مفتوحًا ، قم بتمكين دفع الإخطارات. ستتمكن من التحكم بدقة في أنواع التفاعلات التي تولد إشعارات فورية على نفس الشاشة بمجرد تمكينها.",
          "enable_desktop": "تفعيل تنبيهات النظام",
          "enable_title": "لا تفوت عليك أي شيء",
          "re_auth": "يبدو أن الخادم الخاص بك لا يدعم دفع التنبيهات. حاول تسجيل الخروج ثم تسجيل الدخول مرة أخرى ، إذا استمرت هذه الرسالة في الظهور ، فاتصل بمسؤول الخادم."
        }
      },
      "show_btn": "انتقل إلى إعدادات التنبيهات"
    },
    "notifications_settings": "التنبيهات",
    "preferences": {
      "enable_autoplay": "تفعيل التشغيل التلقائي",
      "github_cards": "بطاقات GitHub",
      "grayscale_mode": "مظهر رمادي",
      "hide_account_hover_card": "إخفاء بطاقة الحساب عند المرور فوقها",
      "hide_boost_count": "إخفاء عدد التعزيز",
      "hide_favorite_count": "إخفاء التعداد المفضل",
      "hide_follower_count": "إخفاء عدد المتابعين",
      "hide_reply_count": "إخفاء عدد الردود",
      "hide_translation": "إخفاء الترجمة",
      "label": "التفضيلات",
      "title": "الميزات التجريبية",
      "user_picker": "منتقي الحسابات",
      "virtual_scroll": "التمرير الافتراضي"
    },
    "profile": {
      "appearance": {
        "bio": "النبذة التعريفية",
        "description": "تعديل الصورة الرمزية واسم المستخدم والملف الشخصي...",
        "display_name": "الاسم المعروض",
        "label": "المظهر",
        "profile_metadata": "البيانات الوصفية للملف الشخصي",
        "profile_metadata_desc": "يمكن أن يكون لديك ما يصل إلى {0} من العناصر المعروضة كجدول في ملفك الشخصي",
        "title": "تعديل الملف الشخصي"
      },
      "featured_tags": {
        "description": "يمكن للأشخاص تصفح مشاركاتك العامة تحت علامات الهاشتاغ هذه",
        "label": "الهاشتاغ البارزة"
      },
      "label": "الملف الشخصي"
    },
    "select_a_settings": "اختر الإعداد",
    "users": {
      "export": "تصدير معلومات المستخدم",
      "import": "استيراد معلومات المستخدم",
      "label": "المستخدمون المسجلون"
    }
  },
  "share-target": {
    "description": "يمكن تحديث Elk بحيث يمكنك مشاركة المحتوى من التطبيقات الأخرى ، ما عليك سوى تثبيت Elk على جهازك أو الكمبيوتر وتسجيل الدخول.",
    "hint": "لمشاركة المحتوى مع Elk ، يجب تثبيت Elk ويجب تسجيل الدخول.",
    "title": "شارك مع Elk"
  },
  "state": {
    "attachments_exceed_server_limit": "تجاوز عدد المرفقات الحد الأقصى لكل منشور.",
    "attachments_limit_error": "تجاوز الحد لل منشور",
    "edited": "(معدل)",
    "editing": "تعديل",
    "loading": "جاري التحميل ...",
    "publish_failed": "فشل النشر",
    "publishing": "قيد النشر",
    "upload_failed": "التحميل فشل",
    "uploading": "جاري التحميل ..."
  },
  "status": {
    "boosted_by": "تم النشر من",
    "edited": "عدل {0}",
    "favourited_by": "مُفضل من",
    "filter_hidden_phrase": "تمت تصفيتها بواسطة",
    "filter_removed_phrase": "تمت إزالته بواسطة عامل التصفية",
    "filter_show_anyway": "عرض على أي حال",
    "img_alt": {
      "desc": "وصف",
      "dismiss": "تجاهل"
    },
    "poll": {
      "count": "لا توجد اصوات|صوت {0}|صوتين|{0} أصوات|{0} صوت|{0} صوت",
      "ends": "ينتهي في {0}",
      "finished": "انتهى في {0}"
    },
    "reblogged": "{0} اعاد نشر",
    "replying_to": "الرد على {0}",
    "show_full_thread": "إظهار المحادثة كاملاً",
    "someone": "شخص ما",
    "spoiler_show_less": "عرض أقل",
    "spoiler_show_more": "عرض المزيد",
    "thread": "المحادثة",
    "try_original_site": "جرب الموقع الأصلي"
  },
  "status_history": {
    "created": "تم إنشاؤه في {0}",
    "edited": "تم تعديله في {0}"
  },
  "tab": {
    "accounts": "الحسابات",
    "for_you": "مصممة لك",
    "hashtags": "هاشتاغ",
    "media": "الصور/الفيديو",
    "news": "الأخبار",
    "notifications_all": "كل شىء",
    "notifications_mention": "موجهة إلي",
    "posts": "المنشورات",
    "posts_with_replies": "المنشورات والردود"
  },
  "tag": {
    "follow": "تابع",
    "follow_label": "اتبع الموضوع {0}",
    "unfollow": "الغاء المتابعة",
    "unfollow_label": "الغاء متابعة الموضوع {0}"
  },
  "time_ago_options": {
    "day_future": "في 0 أيام|غدا|في يومين|في {v} أيام|في {v} يوم|في {v} يوم",
    "day_past": "منذ 0 أيام|البارحة|منذ يومين|منذ {v} أيام|منذ {v} يوم|منذ {v} يوم",
    "hour_future": "في 0 ساعات|في ساعة|في ساعتين|في {v} ساعات|في {v} ساعة|في {v} ساعة",
    "hour_past": "منذ 0 ساعات|منذ ساعة واحدة|منذ ساعتين|منذ {v} ساعات|منذ {v} ساعة|منذ {v} ساعة",
    "just_now": "الآن",
    "minute_future": "في 0 دقائق|في دقيقة واحدة|في دقيقتين|في {v} دقائق|في {v} دقيقة|في {v} دقيقة",
    "minute_past": "منذ 0 دقائق|منذ دقيقة واحدة|منذ دقيقتين|منذ {v} دقائق|منذ {v} دقيقة|منذ {v} دقيقة",
    "month_future": "في 0 أشهر|الشهر القادم|في شهرين|في {v} أشهر|في {v} شهر|في {v} شهر",
    "month_past": "منذ 0 أشهر|الشهر الماضي|منذ شهرين|منذ {v} أشهر|منذ {v} شهر|منذ {v} شهر",
    "second_future": "الآن|في ثانية|في ثانيتين|في {v} ثواني|في {v} ثانية|في {v} ثانية",
    "second_past": "للتو|منذ ثانية|منذ ثانيتين|منذ {v} ثواني|منذ {v} ثانية|منذ {v} ثانية",
    "short_day_future": "في 0 أيام|غدا|في يومين|في {v} أيام|في {v} يوم|في {v} يوم",
    "short_day_past": "منذ 0 أيام|البارحة|منذ يومين|منذ {v} أيام|منذ {v} يوم|منذ {v} يوم",
    "short_hour_future": "في 0 ساعات|في ساعة|في ساعتين|في {v} ساعات|في {v} ساعة|في {v} ساعة",
    "short_hour_past": "منذ 0 ساعات|منذ ساعة واحدة|منذ ساعتين|منذ {v} ساعات|منذ {v} ساعة|منذ {v} ساعة",
    "short_minute_future": "في 0 دقائق|في دقيقة واحدة|في دقيقتين|في {v} دقائق|في {v} دقيقة|في {v} دقيقة",
    "short_minute_past": "منذ 0 دقائق|منذ دقيقة واحدة|منذ دقيقتين|منذ {v} دقائق|منذ {v} دقيقة|منذ {v} دقيقة",
    "short_month_future": "في 0 أشهر|الشهر القادم|في شهرين|في {v} أشهر|في {v} شهر|في {v} شهر",
    "short_month_past": "منذ 0 أشهر|الشهر الماضي|منذ شهرين|منذ {v} أشهر|منذ {v} شهر|منذ {v} شهر",
    "short_second_future": "الآن|في ثانية|في ثانيتين|في {v} ثواني|في {v} ثانية|في {v} ثانية",
    "short_second_past": "للتو|منذ ثانية|منذ ثانيتين|منذ {v} ثواني|منذ {v} ثانية|منذ {v} ثانية",
    "short_week_future": "في 0 أسابيع|الاسبوع القادم|في اسبوعين|في {v} أسابيع|في {v} اسبوع|في {v} اسبوع",
    "short_week_past": "منذ 0 أسابيع|الاسبوع الماضي|منذ اسبوعين|منذ {v} أسابيع|منذ {v} اسبوع|منذ {v} اسبوع",
    "short_year_future": "هذا العام|العام القادم|في عامين|في {v} عاما|في {v} عام|في {v} عام",
    "short_year_past": "هذا العام|العام الماضي|منذ عامين|منذ {v} عاما|منذ {v} عام|منذ {v} عام",
    "week_future": "في 0 أسابيع|الاسبوع القادم|في اسبوعين|في {v} أسابيع|في {v} اسبوع|في {v} اسبوع",
    "week_past": "منذ 0 أسابيع|الاسبوع الماضي|منذ اسبوعين|منذ {v} أسابيع|منذ {v} اسبوع|منذ {v} اسبوع",
    "year_future": "هذا العام|العام القادم|في عامين|في {v} عاما|في {v} عام|في {v} عام",
    "year_past": "هذا العام|العام الماضي|منذ عامين|منذ {v} عاما|منذ {v} عام|منذ {v} عام"
  },
  "timeline": {
    "show_new_items": "لا توجد عناصر جديدة|إظهار {v} عنصر جديد|إظهار {v} عنصرين جديدين|إظهار {v} عناصر جديدة|إظهار {v} عنصر جديد|إظهار {v} عنصر جديد",
    "view_older_posts": "قد لا يتم عرض المنشورات الأقدم من مواقع الأخرى."
  },
  "title": {
    "federated_timeline": "الجدول الزمني الموحد",
    "local_timeline": "الجدول الزمني المحلي"
  },
  "tooltip": {
    "add_content_warning": "إضافة تحذير المحتوى",
    "add_emojis": "إضافة رمز تعبيري",
    "add_media": "أضف صورًا أو مقطع فيديو أو ملفًا صوتيًا",
    "add_publishable_content": "أضف محتوى للنشر",
    "change_content_visibility": "تغيير خصوصية المحتوى",
    "change_language": "تغيير اللغة",
    "emoji": "رمز تعبيري",
    "explore_links_intro": "يتم التحدث عن هذه القصص الإخبارية من قبل الأشخاص الموجودين على هذه الشبكة وغيرها من الشبكات اللامركزية في الوقت الحالي",
    "explore_posts_intro": "تكتسب هذه المنشورات الكثير من النشاط على الشبكة وغيرها من الشبكات اللامركزية في الوقت الحالي",
    "explore_tags_intro": "تكتسب هذه الهاشتاغ الكثير من النشاط بين الأشخاص على هذه الشبكة وغيرها من الشبكات اللامركزية في الوقت الحالي",
    "publish_failed": "أغلق الرسائل الفاشلة أعلى المحرر لإعادة نشر المنشورات",
    "toggle_code_block": "تبديل كتلة التعليمات البرمجية"
  },
  "user": {
    "add_existing": "إضافة حساب قائم",
    "server_address_label": "عنوان خادم ماستودون",
    "sign_in_desc": "قم بتسجيل الدخول لمتابعة الملفات الشخصية والمشاركة والرد على المنشورات أو التفاعل من حسابك على خادم مختلف",
    "sign_in_notice_title": "عرض البيانات العامة من {0}",
    "sign_out_account": "تسجيل الخروج من {0}",
    "tip_no_account": "إذا ليس لديك حساب ماستودون ، {0}",
    "tip_register_account": "اختر خادم ماستودون الخاص بك وقم بتسجيل حساب"
  },
  "visibility": {
    "direct": "رسالة مباشرة",
    "direct_desc": "مرئي للمستخدمين المذكورين فقط",
    "private": "المتابعون فقط",
    "private_desc": "مرئي للمتابعين فقط",
    "public": "عام",
    "public_desc": "مرئي للجميع",
    "unlisted": "غير مدرج",
    "unlisted_desc": "مرئي للجميع ، ولكن تم إلغاء الاشتراك في ميزات الاكتشاف"
  }
}
